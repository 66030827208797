import { TabsProps } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';

import {
  EMarketEventType,
  EOrderClaimCostBearer,
  EOrderClaimReasonType,
  EOrderClaimType,
  EOrderPaymentMethod,
  EOrderPeriodFilter,
  EOrderStatus,
  EOrderType,
  ESalesChannel,
  ESalesChannelType,
  ESettlementTarget,
  EShippingBearer,
} from '../../schema/types';

import {
  CountOrderStatus,
  CsActionType,
  EtcActionType,
  EventFilterType,
} from './types';

// 주문상태: https://github.com/wiredcompany/wired-server/blob/release/2.4.0/libs/common/src/enums/order/order.enum.ts#L25

export const ORDER_COUNT_STATUS: {
  value: CountOrderStatus;
  label: string;
}[] = [
  {
    value: 'PAYMENT_COMPLETED',
    label: '결제완료',
  },
  {
    value: 'DELIVERY_WAIT',
    label: '배송준비',
  },
  {
    value: 'DELIVERY_STARTED',
    label: '배송중',
  },
];

export const ORDER_LIST_TABS_ITEMS: TabsProps['items'] = [
  { key: 'ALL', label: '전체' },
  { key: 'KEMI', label: '케미' },
  { key: 'SELLER_SHOP', label: '셀러쇼핑몰' },
  { key: 'PB_SHOP', label: 'PB몰' },
];

export const PERIOD_OPTIONS: {
  value: EOrderPeriodFilter;
  label: string;
}[] = [
  { label: '주문일시', value: 'ORDERED_AT' },
  { label: '결제일시', value: 'PAID_AT' },
  { label: '주문서 변환일시', value: 'ORDER_CONVERTED_AT' },
];

export const CS_ACTIONS: ItemType<{ key: CsActionType }>[] = [
  {
    label: '반품접수',
    key: 'RETURN',
  },
  {
    label: '교환접수',
    key: 'EXCHANGE',
  },
  {
    label: '취소처리',
    key: 'CANCEL',
  },
  {
    label: '응대관리',
    key: 'RESPONSE_MANAGEMENT',
  },
  {
    label: '부분반품',
    key: 'PARTIAL_RETURN',
  },
  {
    label: '부분교환',
    key: 'PARTIAL_EXCHANGE',
  },
  {
    label: '부분취소',
    key: 'PARTIAL_CANCEL',
  },
];

export const ETC_ACTIONS: ItemType<{ key: EtcActionType }>[] = [
  {
    label: '강제 배송완료 처리',
    key: 'FORCED_DELIVERY_COMPLETED',
  },
  {
    label: '이벤트처리',
    key: 'EVENT_PROCESSING',
  },
  {
    label: '이벤트취소',
    key: 'EVENT_CANCEL',
  },
  {
    label: '결제완료로 변경',
    key: 'CHANGE_TO_PAYMENT_COMPLETED',
  },
  // {
  //   label: '마켓연결',
  //   key: 'MARKET_CONNECTION',
  // },
];

export const ORDER_STATUS_TEXT: { [key in EOrderStatus]: string } = {
  NULL: '-',
  CANCEL_COMPLETED: '취소완료',
  CANCEL_PROGRESS: '취소진행중',
  DELIVERY_COMPLETED: '배송완료',
  DELIVERY_STARTED: '배송중',
  DELIVERY_WAIT: '배송준비',
  EXCHANGE_COMPLETED: '교환완료',
  EXCHANGE_PROGRESS: '교환진행중',
  PAYMENT_COMPLETED: '결제완료',
  PAYMENT_WAIT: '결제대기',
  PURCHASE_CONFIRMED: '구매확정',
  RETURN_COMPLETED: '반품완료',
  RETURN_PROGRESS: '반품진행중',
  VBANK_EXPIRED: '-', // list에서는 보여지지 않는 상태값
};

export const ORDER_STATUS_OPTIONS: { value: EOrderStatus; label: string }[] = [
  { value: 'PAYMENT_WAIT', label: ORDER_STATUS_TEXT['PAYMENT_WAIT'] },
  { value: 'PAYMENT_COMPLETED', label: ORDER_STATUS_TEXT['PAYMENT_COMPLETED'] },
  { value: 'DELIVERY_WAIT', label: ORDER_STATUS_TEXT['DELIVERY_WAIT'] },
  { value: 'DELIVERY_STARTED', label: ORDER_STATUS_TEXT['DELIVERY_STARTED'] },
  {
    value: 'DELIVERY_COMPLETED',
    label: ORDER_STATUS_TEXT['DELIVERY_COMPLETED'],
  },
  {
    value: 'PURCHASE_CONFIRMED',
    label: ORDER_STATUS_TEXT['PURCHASE_CONFIRMED'],
  },
];

export const CLAIM_TYPE_TEXT: { [key in EOrderClaimType]: string } = {
  CANCEL: '취소',
  EXCHANGE: '교환',
  RETURN: '반품',
  PARTIAL_CANCEL: '부분취소',
  PARTIAL_EXCHANGE: '부분교환',
  PARTIAL_RETURN: '부분반품',
};

export const PAYMENT_METHOD_TEXT: { [key in EOrderPaymentMethod]: string } = {
  BANK_TRANSFER: '무통장 입금',
  CARD: '카드',
  ETC: '기타',
  FREE: '무료',
  KAKAO_PAY: '카카오페이',
  NAVER_PAY: '네이버페이',
  PAYCO_PAY: '페이코페이',
  PHONE_PAY: '휴대폰결제',
  VIRTUAL_ACCOUNT: '가상계좌',
};

export const SALES_CHANNEL_TEXT: { [key in ESalesChannel]: string } = {
  BALJU: '발주서변환',
  IMWEB: '아임웹',
  KEMI: '케미',
  MANUAL: 'MANUAL', // 스펙아웃예정
  SMART_STORE: '스마트스토어',
};

export const SALES_CHANNEL_TYPE_TEXT: { [key in ESalesChannelType]: string } = {
  KEMI: '케미',
  PB_SHOP: 'PB몰',
  SELLER_SHOP: '셀러쇼핑몰',
  PB_SHOP_IMWEB: 'PB몰 - 아임웹',
  PB_SHOP_SMART_STORE: 'PB몰 - 스마트스토어',
};

export const EVENT_FILTER_OPTIONS: { value: EventFilterType; label: string }[] =
  [
    { value: 'ALL', label: '이벤트 포함' },
    { value: 'PRODUCT', label: '이벤트 제외' },
    { value: 'EVENT', label: '이벤트만' },
  ];

export const ORDER_TYPE_TEXT: { [key in EOrderType]: string } = {
  EVENT: '이벤트',
  EXTRA_DISCOUNT: '추가할인',
  NORMAL_DELIVERY_FEE: '일반배송비',
  POINT_DISCOUNT: '포인트할인',
  PRODUCT: '상품주문',
  REGION_DELIVERY_FEE: '지역별배송비',
  EXTRA_DELIVERY_FEE: '추가배송비',
};

export const EVENT_TYPE_TEXT: { [key in EMarketEventType]: string } = {
  FIRST_SERVED: '선착순',
  RANDOM: '랜덤',
  TESTER: '체험단',
  CONFIRMED: '구매완료/인증',
  FREE_GIFT: '추가증정',
  BEFORE: '사전',
  ETC: '기타',
};

export const EVENT_SHIPPING_BEARER_TEXT: { [key in EShippingBearer]: string } =
  {
    CUSTOMER: '고객',
    SELLER: '셀러',
    VENDOR: '업체',
    WIRED: '와이어드',
  };

export const CLAIM_REASON_TEXT: { [key in EOrderClaimReasonType]: string } = {
  CHANGE_MIND: '단순변심',
  DELIVERY_DELAY_OR_WRONG: '배송지연 / 배송오류',
  NULL: '-',
  ORDER_MISTAKE: '주문실수',
  PRODUCT_DEFECT: '파손 및 불량',
  CANCEL: '케미스트 처리',
  CHANGE_COLOR_OR_SIZE: '색상, 사이즈 변경',
};

export const ORDER_CLAIM_COST_BEARER_TEXT: {
  [key in EOrderClaimCostBearer]: string;
} = {
  CUSTOMER: '고객',
  SELLER: '셀러',
  DELIVERY: '택배사',
  WIRED: '와이어드',
};

export const ORDER_CLAIM_SETTLEMENT_TARGET_TEXT: {
  [key in ESettlementTarget]: string;
} = {
  SELLER: '셀러',
  VENDOR: '업체',
  WIRED: '와이어드',
};

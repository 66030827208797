import {
  EProductGroupDeliveryFeeType,
  EProductGroupReleaseType,
  EProductGroupStatus,
  EProductUseType,
  ETaxType,
} from '../../schema/types';

import { ProductGroupOrder, SaleType } from './types';

export const PRODUCT_GROUP_STATUSES: {
  value: EProductGroupStatus;
  label: string;
  color?: string;
}[] = [
  { value: 'PENDING', label: '거래중', color: 'blue' },
  { value: 'SELL', label: '거래준비', color: 'magenta' },
  { value: 'HOLD', label: '거래보류', color: 'gold' },
  { value: 'SOLD', label: '거래종료' },
];

export const SHIPPING_TYPE: {
  value: EProductGroupDeliveryFeeType;
  label: string;
}[] = [
  { value: 'FREE', label: '무료배송' },
  { value: 'CHARGED', label: '유료배송' },
  { value: 'CONDITIONAL_FREE', label: '조건부 무료배송' },
];

export const SHIPPING_TYPE_NAMES = SHIPPING_TYPE.reduce(
  (acc, cur) => {
    acc[cur.value] = cur.label;
    return acc;
  },
  {} as Record<EProductGroupDeliveryFeeType, string>
);

export const SALES_TYPE_OPTIONS: {
  value: SaleType;
  label: string;
}[] = [
  { value: 'BUSINESS', label: '사업자' },
  { value: 'FREELANCER', label: '프리랜서' },
];

export const RELEASE_TYPE: {
  value: EProductGroupReleaseType;
  label: string;
}[] = [
  { value: 'TODAY', label: '당일출고' },
  { value: 'BULK', label: '일괄출고' },
  { value: 'NEXT_DAY', label: '익일출고' },
];

export const PRODUCT_USE_TYPE: {
  value: EProductUseType;
  label: string;
}[] = [
  { value: 'FIX', label: '고정타입' },
  { value: 'SELECT', label: '선택타입' },
];

export const PRODUCT_TAX_TYPE: {
  value: ETaxType;
  label: string;
}[] = [
  { value: 'TAX', label: '과세' },
  { value: 'FREE', label: '면세' },
];

export const PRODUCT_GROUP_MENU = [
  {
    label: '상품 제안서',
    key: 'PROPOSAL',
  },
  {
    label: '샘플 출고요청',
    key: 'SAMPLE',
    disabled: true,
  },
  {
    label: '복제',
    key: 'COPY',
  },
  {
    label: '삭제',
    key: 'DELETE',
    className: 'text-red-500',
  },
];

export const PRODUCT_GROUP_MENU_DISABLED = [
  {
    label: '상품 제안서',
    key: 'PROPOSAL',
  },
  {
    label: '샘플 출고요청',
    key: 'SAMPLE',
    disabled: true,
  },
  {
    label: '복제',
    key: 'COPY',
    disabled: true,
  },
  {
    label: '삭제',
    key: 'DELETE',
    disabled: true,
  },
];

export const PRODUCT_GROUP_ORDER: {
  value: ProductGroupOrder;
  label: string;
}[] = [
  { value: 'updatedAt', label: '업데이트 순' },
  { value: 'jointPurchasePrice', label: '공동구매가 높은순' },
  {
    value: 'businessSellerMarginRate',
    label: '셀러마진율 높은순',
  },
];

export const EXCEL_DATA_FIELD_NAMES = [
  '*품목명',
  '공동구매가',
  '과세/면세',
  '비고',
  '셀러공급가',
  '셀러마진',
  '셀러마진율',
  '소비자가',
  '온라인최저가',
  '옵션명',
  '자사마진',
  '자사마진율',
  '자사매입가',
];

export const PRODUCT_GROUP_TYPE = [
  { value: 'DISTRIBUTION', label: '유통상품' },
  { value: 'PB', label: 'PB' },
];

export const PRODUCT_NAME_MAX_LENGTH = 30;

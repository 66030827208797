import * as yup from 'yup';

import { ERROR_MESSAGE_REQUIRED } from '../../common/constants/error';
import {
  CreateOrderSupportWithMessageInputGraphqlType,
  EOrderClaimCostBearer,
  EOrderClaimReasonType,
  EOrderClaimStatus,
  EOrderClaimTarget,
  EOrderClaimType,
  EOrderSupportChannelType,
  EOrderSupportRequestType,
  ESettlementTarget,
} from '../../schema/types';

import {
  orderSupportChannelOptions,
  orderSupportRequestTypeOptions,
} from './const';
import { CreateClaimFormValues, UpdateClaimFormValues } from './type';

const createClaimSchemaObject = {
  claimType: yup
    .mixed<EOrderClaimType>()
    .oneOf([
      'RETURN',
      'EXCHANGE',
      'CANCEL',
      'PARTIAL_RETURN',
      'PARTIAL_EXCHANGE',
      'PARTIAL_CANCEL',
    ])
    .required(ERROR_MESSAGE_REQUIRED),
  isWiredOwnDeliveryFault: yup.boolean(),
  reason: yup.string(),
  reasonType: yup
    .mixed<EOrderClaimReasonType>()
    .oneOf([
      'CHANGE_MIND',
      'DELIVERY_DELAY_OR_WRONG',
      'ORDER_MISTAKE',
      'PRODUCT_DEFECT',
      'NULL',
      'CANCEL',
      'CHANGE_COLOR_OR_SIZE',
    ])
    .required(ERROR_MESSAGE_REQUIRED),

  orders: yup
    .array()
    .of(
      yup.object().shape({
        orderId: yup.number().required(ERROR_MESSAGE_REQUIRED),
        claimQuantity: yup
          .number()
          .min(1, '1이상의 숫자를 입력해야 합니다.')
          .required(ERROR_MESSAGE_REQUIRED),
      })
    )
    .required(),

  target: yup
    .mixed<EOrderClaimTarget>()
    .oneOf(['BUYER', 'SELLER'])
    .required(ERROR_MESSAGE_REQUIRED),
  status: yup
    .mixed<EOrderClaimStatus>()
    .oneOf([
      'CANCEL_NOT_APPROVED',
      'CANCEL_REQUESTED',
      'EXCHANGE_CANCELED',
      'EXCHANGE_COMPLETED',
      'EXCHANGE_RECEIPT',
      'EXCHANGE_REQUESTED',
      'EXCHANGE_RE_DELIVERY',
      'RETURN_CANCELED',
      'RETURN_COMPLETED',
      'RETURN_RECEIPT',
      'RETURN_RECLAIM_COMPLETED',
      'RETURN_REQUESTED',
    ])
    .required(ERROR_MESSAGE_REQUIRED),
};

export const createClaimSchema = yup
  .object<CreateClaimFormValues>()
  .shape(createClaimSchemaObject);

export const updateClaimSchema = yup.object<UpdateClaimFormValues>().shape({
  ...createClaimSchemaObject,
  extraDelivery: yup
    .object()
    .shape({
      deliveryFee: yup
        .number()
        .min(1, '1이상 숫자를 입력해주세요.')
        .required(ERROR_MESSAGE_REQUIRED),
      deliveryFeeBearer: yup
        .mixed<EOrderClaimCostBearer>()
        .oneOf(['DELIVERY', 'SELLER', 'WIRED', 'CUSTOMER'])
        .required(ERROR_MESSAGE_REQUIRED),
      deliveryFeeSettlementTarget: yup
        .mixed<ESettlementTarget>()
        .oneOf(['SELLER', 'VENDOR', 'WIRED'])
        .required(ERROR_MESSAGE_REQUIRED),
      deliveryFeeSettlementPrice: yup
        .number()
        .min(1, '1이상 숫자를 입력해주세요.')
        .required(ERROR_MESSAGE_REQUIRED),
    })
    .optional()
    .nullable(),
  hasExtraDelivery: yup.boolean().required(),
});

export const createOrderSupportMessageSchema = yup
  .object<CreateOrderSupportWithMessageInputGraphqlType>()
  .shape({
    channel: yup
      .mixed<EOrderSupportChannelType>()
      .oneOf(orderSupportChannelOptions.map((option) => option.value))
      .required(ERROR_MESSAGE_REQUIRED),
    message: yup
      .object()
      .shape({
        content: yup.string().required(ERROR_MESSAGE_REQUIRED),
      })
      .required(),
    requestType: yup
      .mixed<EOrderSupportRequestType>()
      .oneOf(orderSupportRequestTypeOptions.map((option) => option.value))
      .required(ERROR_MESSAGE_REQUIRED),
    orderId: yup.number().required(),
  });

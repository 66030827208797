import * as Types from '../../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminOrderQueryVariables = Types.Exact<{
  adminOrderId: Types.Scalars['Int']['input'];
}>;


export type AdminOrderQuery = { __typename?: 'Query', adminOrder: { __typename?: 'OrderGraphqlType', id: number, orderType: Types.EOrderType, initialReleasedAt?: string | null, orderConvertedAt?: string | null, salesChannel: Types.ESalesChannel, salesChannelProductName?: string | null, salesChannelOrderCode?: string | null, status: Types.EOrderStatus, orderAmount: number, code: string, productName: string, productQuantity: number, receiverName: string, receiverPhoneNumber: string, receiverAddress: string, receiverDetailAddress?: string | null, receiverZipCode: string, receiverDeliveryMemo?: string | null, deliveryCompanyName?: string | null, trackingNumber?: string | null, marketEventId?: number | null, productGroupId: number, productCode: string, productGroupName: string, group: { __typename?: 'OrderGroupGraphqlType', orderedAt?: string | null, paidAt?: string | null, salesChannelOrderGroupCode?: string | null, ordererName?: string | null, ordererPhoneNumber?: string | null, marketId?: number | null, marketName?: string | null }, items?: Array<{ __typename?: 'OrderItemGraphqlType', salesChannelItemName?: string | null, name: string, itemId: number, setCount: number, itemCode: string }> | null, claims?: Array<{ __typename?: 'OrderClaimGraphqlType', id: number, createdAt: string, claimQuantity?: number | null, claimType: Types.EOrderClaimType, reason?: string | null, reasonType: Types.EOrderClaimReasonType, status: Types.EOrderClaimStatus, extraDeliveryFee?: number | null, extraDeliveryFeeBearer?: Types.EOrderClaimCostBearer | null, extraDeliverySettlementPrice?: number | null, extraDeliverySettlementTarget?: Types.ESettlementTarget | null }> | null } };

export type AdminMarketEventInOrderQueryVariables = Types.Exact<{
  adminMarketEventId: Types.Scalars['Int']['input'];
}>;


export type AdminMarketEventInOrderQuery = { __typename?: 'Query', adminMarketEvent: { __typename?: 'MarketEventGraphqlType', type: Types.EMarketEventType, count?: number | null, comment?: string | null, shippingBearer?: Types.EShippingBearer | null, shippingCost?: number | null, products: Array<{ __typename?: 'MarketEventProductGraphqlType', id: number, isFreeProvided: boolean, name: string, count: number, sellerBearingCost?: number | null, wiredBearingCost?: number | null, customerBearingCost?: number | null, items?: Array<{ __typename?: 'ItemGraphqlType', name: string }> | null }> } };


export const AdminOrderDocument = gql`
    query AdminOrder($adminOrderId: Int!) {
  adminOrder(id: $adminOrderId) {
    id
    orderType
    group {
      orderedAt
      paidAt
      salesChannelOrderGroupCode
      ordererName
      ordererPhoneNumber
      marketId
      marketName
    }
    initialReleasedAt
    orderConvertedAt
    salesChannel
    salesChannelProductName
    items {
      salesChannelItemName
      name
      itemId
      setCount
      itemCode
    }
    salesChannelOrderCode
    status
    orderAmount
    code
    productName
    productQuantity
    claims {
      id
      createdAt
      claimQuantity
      claimType
      reason
      reasonType
      status
      extraDeliveryFee
      extraDeliveryFeeBearer
      extraDeliverySettlementPrice
      extraDeliverySettlementTarget
    }
    receiverName
    receiverPhoneNumber
    receiverAddress
    receiverDetailAddress
    receiverZipCode
    receiverDeliveryMemo
    deliveryCompanyName
    trackingNumber
    marketEventId
    productGroupId
    productCode
    productGroupName
  }
}
    `;

/**
 * __useAdminOrderQuery__
 *
 * To run a query within a React component, call `useAdminOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminOrderQuery({
 *   variables: {
 *      adminOrderId: // value for 'adminOrderId'
 *   },
 * });
 */
export function useAdminOrderQuery(baseOptions: Apollo.QueryHookOptions<AdminOrderQuery, AdminOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminOrderQuery, AdminOrderQueryVariables>(AdminOrderDocument, options);
      }
export function useAdminOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminOrderQuery, AdminOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminOrderQuery, AdminOrderQueryVariables>(AdminOrderDocument, options);
        }
export type AdminOrderQueryHookResult = ReturnType<typeof useAdminOrderQuery>;
export type AdminOrderLazyQueryHookResult = ReturnType<typeof useAdminOrderLazyQuery>;
export type AdminOrderQueryResult = Apollo.QueryResult<AdminOrderQuery, AdminOrderQueryVariables>;
export const AdminMarketEventInOrderDocument = gql`
    query AdminMarketEventInOrder($adminMarketEventId: Int!) {
  adminMarketEvent(id: $adminMarketEventId) {
    type
    count
    comment
    shippingBearer
    shippingCost
    products {
      id
      isFreeProvided
      name
      items {
        name
      }
      count
      sellerBearingCost
      wiredBearingCost
      customerBearingCost
    }
  }
}
    `;

/**
 * __useAdminMarketEventInOrderQuery__
 *
 * To run a query within a React component, call `useAdminMarketEventInOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminMarketEventInOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminMarketEventInOrderQuery({
 *   variables: {
 *      adminMarketEventId: // value for 'adminMarketEventId'
 *   },
 * });
 */
export function useAdminMarketEventInOrderQuery(baseOptions: Apollo.QueryHookOptions<AdminMarketEventInOrderQuery, AdminMarketEventInOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminMarketEventInOrderQuery, AdminMarketEventInOrderQueryVariables>(AdminMarketEventInOrderDocument, options);
      }
export function useAdminMarketEventInOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminMarketEventInOrderQuery, AdminMarketEventInOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminMarketEventInOrderQuery, AdminMarketEventInOrderQueryVariables>(AdminMarketEventInOrderDocument, options);
        }
export type AdminMarketEventInOrderQueryHookResult = ReturnType<typeof useAdminMarketEventInOrderQuery>;
export type AdminMarketEventInOrderLazyQueryHookResult = ReturnType<typeof useAdminMarketEventInOrderLazyQuery>;
export type AdminMarketEventInOrderQueryResult = Apollo.QueryResult<AdminMarketEventInOrderQuery, AdminMarketEventInOrderQueryVariables>;
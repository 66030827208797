import { ApolloCache } from '@apollo/client';
import { App } from 'antd';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { HistoriesDocument } from '../../../common/graphql/history.generated';
import { deleteCacheFields } from '../../../common/utils/apollo';
import { ROUTES } from '../../../routes/const';
import {
  AdminUpdatePromotionReleaseMutationVariables,
  useAdminUpdatePromotionReleaseMutation,
} from '../graphql/promotionRelease.generated';
import { getUpdateResolver } from '../validator/updateReleaseValidator';

import useReleaseDetail from './useReleaseDetail';

function useUpdateRelease() {
  const navigate = useNavigate();

  const { message } = App.useApp();
  const { releaseId, data, isCompleted } = useReleaseDetail();
  const [updateRelease] = useAdminUpdatePromotionReleaseMutation();
  const methods = useForm<AdminUpdatePromotionReleaseMutationVariables>({
    defaultValues: {
      adminUpdatePromotionReleaseId: releaseId,
      promotionReleaseData: {
        deliveryMessage: data?.adminPromotionRelease.deliveryMessage,
        note: data?.adminPromotionRelease.note,
        receiverName: data?.adminPromotionRelease.receiverName,
        receiverAddress: data?.adminPromotionRelease.receiverAddress,
        receiverDetailAddress:
          data?.adminPromotionRelease.receiverDetailAddress,
        receiverPhoneNumber: data?.adminPromotionRelease.receiverPhoneNumber,
        scheduledReleaseAt: data?.adminPromotionRelease.scheduledReleaseAt,
        sellerId: data?.adminPromotionRelease.seller?.id,
        sellerSettlementInfoId:
          data?.adminPromotionRelease.sellerSettlementInfoId,
        zipCode: data?.adminPromotionRelease.zipCode,
      },
    },
    resolver: getUpdateResolver(!!data?.adminPromotionRelease.seller),
  });
  const onSubmit = methods.handleSubmit(
    async ({ adminUpdatePromotionReleaseId, promotionReleaseData }) => {
      await updateRelease({
        variables: {
          adminUpdatePromotionReleaseId,
          promotionReleaseData: isCompleted
            ? {
                receiverName: promotionReleaseData.receiverName,
                receiverPhoneNumber: promotionReleaseData.receiverPhoneNumber,
                receiverAddress: promotionReleaseData.receiverAddress,
                note: promotionReleaseData.note,
              }
            : {
                ...promotionReleaseData,
                scheduledReleaseAt:
                  promotionReleaseData.scheduledReleaseAt || null,
              },
        },
        refetchQueries: [HistoriesDocument],
        update: (cache: ApolloCache<any>) =>
          deleteCacheFields(cache, ['adminPromotionReleases']),
        onCompleted() {
          void message.success('저장되었습니다.');
          navigate(ROUTES.PROMOTION_RELEASES.path);
        },
        onError() {
          void message.error('저장을 실패하였습니다.');
        },
      });
    }
  );
  return {
    methods,
    onSubmit,
  };
}

export default useUpdateRelease;

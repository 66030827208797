import { FilterOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, Tabs, Typography } from 'antd';
import { useCallback, useEffect, useState } from 'react';

import useRouteSearchParams from '../common/hooks/useRouteSearchParams';
import CreatePromotionModal from '../domain/promotion-release/components/CreatePromotionModal/CreatePromotionModal';
import InvoiceUploadModal from '../domain/promotion-release/components/InvoiceUploadModal';
import PromotionReleaseTable from '../domain/promotion-release/components/PromotionReleaseTable';
import SearchFilterModal from '../domain/promotion-release/components/SearchFilterModal';
import SearchTags from '../domain/promotion-release/components/SearchTags';
import Summary from '../domain/promotion-release/components/Summary';
import useExcelHandler from '../domain/promotion-release/hooks/useExcelHandler';
import usePromotionPermission from '../domain/promotion-release/hooks/usePromotionPermission';
import { PromotionReleaseListSearchParams } from '../domain/promotion-release/types';

const TAB_ITEMS = [
  { key: 'ALL', label: '전체' },
  { key: 'SAMPLE', label: '샘플' },
  { key: 'EVENT', label: '이벤트' },
  { key: 'EXCHANGE_RE_DELIVERY', label: '교환재출고' },
  { key: 'EXTRA', label: '기타' },
];

function PromotionReleaseList() {
  const hasPermission = usePromotionPermission();
  const [filterModal, setFilterModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [invoiceModal, setInvoiceModal] = useState(false);
  const { searchParams, setSearchParams } =
    useRouteSearchParams<PromotionReleaseListSearchParams>();

  const handleSearchKeyword = useCallback(
    (value: string) => {
      setSearchParams({
        searchKeyword: value,
        summaryReleaseStatus: undefined,
        summarySampleStatus: undefined,
        currentPage: undefined,
      });
    },
    [setSearchParams]
  );
  useEffect(() => {
    if (Object.keys(searchParams).length === 0) {
      setSearchParams({ summaryReleaseStatus: 'RELEASE_REQUEST' });
    }
  }, []);
  const { handleExcelDownload } = useExcelHandler();
  return (
    <div className='flex flex-col gap-4'>
      <div className='bg-white p-6'>
        <Tabs
          items={TAB_ITEMS}
          destroyInactiveTabPane
          defaultActiveKey={searchParams.promotionType || 'ALL'}
          onChange={(activeKey) =>
            setSearchParams({
              promotionType:
                activeKey as PromotionReleaseListSearchParams['promotionType'],
              summaryReleaseStatus: undefined,
              summarySampleStatus: undefined,
              currentPage: undefined,
            })
          }
        />
        <Summary />
      </div>
      <div className='bg-white p-6'>
        <Typography.Title level={5}>검색</Typography.Title>
        <div className='flex gap-2'>
          <Input.Search
            defaultValue={searchParams.searchKeyword}
            placeholder='출고코드, 셀러명, 마켓명, 주문업체명, 상품명, 수령자명 입력'
            className='w-[616px]'
            onSearch={handleSearchKeyword}
          />
          <Button
            icon={<FilterOutlined />}
            onClick={() => setFilterModal(true)}
          />
        </div>
        <SearchTags />
      </div>
      <div className='flex flex-col gap-4 bg-white p-6'>
        <div className='flex justify-between'>
          <Typography.Title level={4} className='m-0'>
            출고요청 리스트
          </Typography.Title>
          <Button
            type='primary'
            icon={<PlusOutlined />}
            onClick={() => setCreateModal(true)}
            disabled={!hasPermission}
          >
            출고요청
          </Button>
        </div>
        <PromotionReleaseTable>
          <div className='flex gap-2'>
            <Button onClick={() => setInvoiceModal(true)}>
              송장 일괄업로드
            </Button>
            <Button onClick={handleExcelDownload}>전체 다운로드</Button>
          </div>
        </PromotionReleaseTable>
      </div>
      {filterModal && (
        <SearchFilterModal onClose={() => setFilterModal(false)} />
      )}
      {createModal && (
        <CreatePromotionModal onClose={() => setCreateModal(false)} />
      )}
      {invoiceModal && (
        <InvoiceUploadModal onClose={() => setInvoiceModal(false)} />
      )}
    </div>
  );
}

export default PromotionReleaseList;
